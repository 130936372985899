// import * as React from "react";
// import SwipeableDrawer from "@mui/material/SwipeableDrawer";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemButton from "@mui/material/ListItemButton";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import ListItemText from "@mui/material/ListItemText";
// import InboxIcon from "@mui/icons-material/MoveToInbox";
// import MailIcon from "@mui/icons-material/Mail";
// import MenuIcon from "@mui/icons-material/Menu";
// import { Box, Button, Grid, InputAdornment, Typography, TextField, Modal, CircularProgress, Divider } from "@material-ui/core";
// import { getUserInfo } from "../../services/getUserInfo";
// import { Auth } from "aws-amplify";
// import useStyles from "../../assests/css/profileStyle";
// import "../../assests/css/style.css";
// import { useHistory } from "react-router-dom";
// import { CheckIfPasswordIsValid, getBase64 } from "../../services/utils";
// import { getUserImage, uploadImageName } from "../../api";
// import { CModal, CroppieModal } from "..";
// import { Visibility, VisibilityOff } from "@material-ui/icons";
// import Habilelabs from "../../assests/images/habilelabs-squareLogo.png";
// import LogOut from "../../assests/images/logout.svg";
// import HomePage from "../../assests/images/home-page.svg";
// import LogOut1 from "../../assests/images/logout1.svg";
// import UpdateProfile from "../../assests/images/update-profile.svg";
// import changePassword1 from "../../assests/images/change-password.svg"
// import { ReactSVG } from "react-svg";

// export default function SwipeableTemporaryDrawer(props) {

//   const [state, setState] = React.useState({
//     left: false,
//   });
//   console.log('propspropsprops', props)
//   const [sidebarIsOpen, setSidebarIsOpen] = React.useState(false);

//   const toggleDrawer = () => {
//     setSidebarIsOpen(!sidebarIsOpen);
//     getUserEmailUserName();
//   };
//   const toggleSidebar = () => {
//     props.setIsOpen(!props.isOpen);
//     // getUserEmailUserName();
//   };
//   console.log('cccccccccccccc', window.location)
//   const history = useHistory();
//   const [open, setOpen] = React.useState(false);
//   const [openChangePassword, setOpenChangePassword] = React.useState(false);
//   const handleClose = () => setOpen(false);

// const handleCloseChangePassword = () => {
//   setOpenChangePassword(false);
//   setShowError(false);
//   setOldPassword("");
//   setNewPassword("");
//   setConfirmPassword("");
// };
//   const handleOpenChangePassword = () => setOpenChangePassword(true);
//   const handleOpen = () => setOpen(true);
//   const classes = useStyles();
//   const [userDetails, setUserDetails] = React.useState({});
//   const name = userDetails.email && userDetails.email.split("@")[0];
//   const [userName, setUserName] = React.useState("");
//   const [openName, setOpenName] = React.useState(false);
//   const [isLoading, setIsLoading] = React.useState(false);
//   const [userNameNew, setUserNameNew] = React.useState("");
//   const [showError, setShowError] = React.useState(false);
//   const [showImageError, setImageShowError] = React.useState(false);
//   const [croppedImage, setCroppedImage] = React.useState("");
//   const handleOpenName = () => {
//     setUserNameNew(userName);
//     setOpenName(true);
//   };
//   const handleCloseName = () => setOpenName(false);
//   const [img, setImg] = React.useState();
//   const [oldPassword, setOldPassword] = React.useState("");
//   const [newPassword, setNewPassword] = React.useState("");
//   const [imageUpload, setImageUpload] = React.useState(false);
//   const [selectedImg, setSelectedImg] = React.useState(null);
//   const [confirmPassword, setConfirmPassword] = React.useState("");
//   const [oldPasswordShow, setOldPasswordShow] = React.useState(false);
//   const [newPasswordShow, setNewPasswordShow] = React.useState(false);
//   const [confirmPasswordShow, setConfirmPasswordShow] = React.useState(false);
//   const id = btoa(name && name.replace(".", ""));
//   const navigate = "https://www.habilelabs.io/contact-us";

//   const handleCloseImageUpload = () => {
//     setImageUpload(false);
//   };
//   React.useEffect(() => {
//     getUserDetails();
//   }, []);

//   React.useEffect(() => {
//     getUserData();
//   }, []);
//   React.useEffect(() => {
//     localStorage.setItem("BottomNavigationScreenIndex", 2);
//   }, []);
//   React.useEffect(() => {
//     return () => {
//       localStorage.setItem("BottomNavigationScreenIndex", 2);
//     };
//   });

//   React.useEffect(() => {
//   }, [img]);
//   const getUserDetails = () => {
//     const details = getUserInfo();
//     setUserDetails(details);
//   };

//   const getUserData = async () => {
//     const details = await getUserInfo();
//     setUserDetails(details);
//   };

// const signOutBtn = () => {
//   localStorage.clear();
//   window.location.reload();
// };

//   const changeUserName = () => {
//     setUserName(userNameNew);
//     const imageBase64 =
//       localStorage.getItem(`${name}_img`) &&
//       localStorage.getItem(`${name}_img`).split(",")[1];
//     saveImageAndUserName(userNameNew, imageBase64);
//     handleCloseName();
//   };
//   const saveImageAndUserName = async (updateName, image) => {
//     const userName = name + "_" + updateName;

//     const out = await uploadImageName(userName, image);
//   };

//   const getUserEmailUserName = async () => {
//     setIsLoading(true);
//     const imageData = await getUserImage(name);
//     if (imageData) {
//       setImg(imageData.files.link);
//       const data = imageData.files.link;
//       setUserName(
//         data
//           .substr(data.lastIndexOf("/") + 1)
//           .split(".")[0]
//           .split("_")[1]
//       );
//       console.log("imageData.files.link", imageData.files.link);
//       if (imageData.files.link) {
//         setIsLoading(false);
//       }
//     }
//   };
//   function getRandomKey() {
//     return `?key=${Math.random()}`;
//   }
// const handleChangePassword = () => {
//   const isValid = CheckIfPasswordIsValid(
//     oldPassword,
//     newPassword,
//     confirmPassword
//   );
//   if (isValid) return setShowError(isValid);
//   Auth.currentAuthenticatedUser()
//     .then((user) => {
//       return Auth.changePassword(user, oldPassword, newPassword);
//     })
//     .then((data) => {
//       if (data === "SUCCESS") {
//         signOutBtn();
//         history.push("/");
//         setOldPassword("");
//         setNewPassword("");
//         setConfirmPassword("");
//         handleCloseChangePassword();
//       } else {
//         setShowError("Old password not matched!");
//       }
//     })
//     .catch((err) => setShowError("Old password not matched!"));
// };

// const handleChange = (e, type) => {
//   if (type === "old") setOldPassword(e.target.value);
//   if (type === "new") setNewPassword(e.target.value);

//   if (type === "confirm") setConfirmPassword(e.target.value);
//   setShowError(false);
// };

//   const uploadImage = () => {
//     if (selectedImg.size > 2500000) {
//       handleCloseImageUpload();
//       return setImageShowError("Image should be only max 2MB");
//     }
//     setImageShowError(false);
//     getBase64(selectedImg).then((base64) => {
//       localStorage["img"] = base64;
//       console.log("settingSideIMg", base64);
//       console.log("base64.split(", ")[1]", base64);
//       localStorage.setItem("img", base64);
//       saveImageAndUserName(userName, base64.split(",")[1]);
//       handleCloseImageUpload();
//     });
//   };
// const homePageNavigator = () => {
//   props.handleDeviceAdded && props.handleDeviceAdded();
// };

//   return (
//     <div>
//       {["left"].map((anchor) => (
//         <React.Fragment key={anchor}>

//           {/* <SwipeableDrawer
//             anchor="left"
//             open={sidebarIsOpen}
//             onClose={toggleDrawer}
//             onOpen={toggleDrawer}
//           > */}
//           <div className={`${props.isOpen ? 'sidebarOpen' : 'sidebarClosed'}`}>
//             <Grid container className={classes.main}>
//               {isLoading ? (
//                 <CircularProgress />
//               ) : (
//                 <Grid
//                   item
//                   xs={12}
//                   sm={12}
//                   md={12}
//                   lg={12}
//                   className={classes.profile}>
//                   <Box className={classes.upperProfile}>
// <div style={{ display: 'flex' }}>
//   <img
//     src={Habilelabs}
//     name="file"
//     className="img-thumbnail"
//     height={props.isOpen ? 100 : 65}
//     width={props.isOpen ? marginT0 : 40}
//     alt="profile"
//   />
//                       <Button onClick={toggleSidebar}>
//                         {<MenuIcon style={{ fill: "#FFF" }} />}
//                       </Button>
//                     </div>


//                     {showImageError && <span>{showImageError}</span>}
//                     <CModal
//                       maxWidth="xs"
//                       open={imageUpload}
//                       handleClose={handleCloseImageUpload}
//                       bgClick={true}
//                     >
//                       <CroppieModal
//                         image={selectedImg}
//                         uploadImage={uploadImage}
//                         uploading={false}
//                         closeModal={handleCloseImageUpload}
//                         modalTitle={"Change profile picture"}
//                         error={showImageError}
//                         setImage={setCroppedImage}
//                       />
//                       <Grid container>
//                         <Grid item xs={6} align={"left"}>
//                           <div className={classes.cancelBtn}>
//                             <Button
//                               variant="outlined"
//                               onClick={handleCloseImageUpload}
//                             >
//                               Cancel
//                             </Button>
//                           </div>
//                         </Grid>
//                         <Grid item xs={6} align={"right"}>
//                           <div className={classes.uploadBtn}>
//                             <Button
//                               variant="outlined"
//                               disabled={!selectedImg && !showImageError}
//                               onClick={() => uploadImage(croppedImage)}
//                             >
//                               Upload
//                             </Button>
//                           </div>
//                         </Grid>
//                       </Grid>
//                     </CModal>
//                   </Box>
//                   <Box className={classes.userButton}>
//                     <div>
//                       <Button
//                         onClick={() =>
//                           new URL(window.location.href).pathname === "/"
//                             ? homePageNavigator(anchor) &&
//                             toggleDrawer(anchor, false)()
//                             : history.push(`/`)}
//                       >
//                         <div style={{ display: 'flex' }}>
//                           <ReactSVG src={HomePage} />
//                           {props.isOpen && <p style={{ paddingLeft: '20px', margin: '0px' }}>Home Page</p>}
//                         </div>

//                       </Button>
//                       <Button onClick={() => history.push(`/edit-profile`)}>
//                         <div style={{ display: 'flex', alignItems: 'center' }}>
//                           <ReactSVG src={UpdateProfile} />
//                           {props.isOpen && <p style={{ paddingLeft: '20px', margin: '0px' }}>Update Profile</p>}

//                         </div>
//                       </Button>
//                       <Button onClick={handleOpenChangePassword}>
//                         <div style={{ display: 'flex' }}>
//                           <ReactSVG src={changePassword1} />
//                           {props.isOpen && <p style={{ paddingLeft: '20px', margin: '0px' }}>Change Password</p>}

//                         </div>
//                       </Button>
//                       <Button onClick={handleOpen}>
//                         <div style={{ display: 'flex' }}>
//                           <ReactSVG src={LogOut1} />
//                           {props.isOpen && <p style={{ paddingLeft: '20px', margin: '0px' }}>Log Out</p>}

//                         </div>
//                       </Button>
//                     </div>
//                     {/* Modal for logout confirmation */}
//                     <Modal
//                       open={open}
//                       onClose={handleClose}
//                       aria-labelledby="modal-modal-title"
//                       aria-describedby="modal-modal-description"
//                     >
//                       <Box
//                         className={classes.modal + " " + classes.changePassword}
//                       >
// <Typography
//   id="modal-modal-description"
//   className={classes.modalBod}
// >
//   Are you sure you want to logout ?
// </Typography>
// <Box className={classes.modalFooter}>
//   <Button
//     variant="text"
//     size="medium"
//     onClick={handleClose}
//     style={{
//       backgroundColor: "#FFF",
//       margin: "13px",
//       color: "#000",
//       border: '1px solid gray'
//     }}
//   >
//     No
//   </Button>
//   <Button
//     variant="text"
//     size="medium"
//     onClick={signOutBtn}
//     style={{
//       backgroundColor: "#060543",

//       margin: "13px",
//       color: "white",
//     }}
//   >
//     Yes
//   </Button>
// </Box>
//                       </Box>
//                     </Modal>
//                     {/* Modal for changing name */}
//                     <Modal
//                       open={openName}
//                       onClose={handleCloseName}
//                       aria-labelledby="modal-modal-title"
//                       aria-describedby="modal-modal-description"
//                     >
//                       <Box
//                         className={classes.modal + " " + classes.changePassword}
//                       >
//                         <Typography
//                           id="modal-modal-title"
//                           variant="h6"
//                           component="h2"
//                           className={classes.modalHeader}
//                         >
//                           Enter new name
//                         </Typography>
//                         <Box className={classes.modalFooter}>
//                           <div
//                             style={{ flexDirection: "row", display: "flex" }}
//                           >
//                             <TextField
//                               fullWidth
//                               required
//                               value={userNameNew}
//                               onChange={(e) => {
//                                 setUserNameNew(e.target.value);
//                               }}
//                             />
//                           </div>
//                           <div className={classes.modalFooter1}>
//                             <Button
//                               variant="text"
//                               size="medium"
//                               onClick={handleCloseName}
//                             >
//                               cancel
//                             </Button>
//                             <Button
//                               variant="text"
//                               size="medium"
//                               onClick={changeUserName}
//                               disabled={!userNameNew}
//                             >
//                               confirm
//                             </Button>
//                           </div>
//                         </Box>
//                       </Box>
//                     </Modal>
//                     {/* Modal for changing password */}
//                     <Modal
//                       open={openChangePassword}
//                       onClose={handleCloseChangePassword}
//                       aria-labelledby="modal-modal-title"
//                       aria-describedby="modal-modal-description"
//                     >
//                       <Box
//                         className={classes.modal + " " + classes.changePassword}
//                       >
//                         <Typography
//                           id="modal-modal-description"
//                           style={{ fontSize: 18 }}
//                         >
//                           Change Login Password
//                         </Typography>
//                         <Box className={classes.modalFooter}>
//                           <div
//                             style={{ flexDirection: "row", display: "flex" }}
//                           >
//                             <TextField
//                               fullWidth
//                               placeholder={"Old Password "}
//                               autoComplete={false}
//                               required
//                               type={oldPasswordShow ? "text" : "password"}
//                               value={oldPassword}
//                               onChange={(e) => handleChange(e, "old")}
//                               InputProps={{
//                                 endAdornment: (
//                                   <InputAdornment position="end">
//                                     {oldPasswordShow ? (
//                                       <Visibility
//                                         style={{ color: "black" }}
//                                         onClick={() =>
//                                           setOldPasswordShow(!oldPasswordShow)
//                                         }
//                                       />
//                                     ) : (
//                                       <VisibilityOff
//                                         style={{ color: "black" }}
//                                         onClick={() =>
//                                           setOldPasswordShow(!oldPasswordShow)
//                                         }
//                                       />
//                                     )}
//                                   </InputAdornment>
//                                 ),
//                               }}
//                             />
//                           </div>
//                           <div style={{ flexDirection: "row", display: "flex" }} >
//                             <TextField
//                               fullWidth
//                               placeholder={"New Password "}
//                               type={newPasswordShow ? "text" : "password"}
//                               autoComplete={false}
//                               required
//                               value={newPassword}
//                               onChange={(e) => handleChange(e, "new")}
//                               InputProps={{
//                                 endAdornment: (
//                                   <InputAdornment position="end">
//                                     {newPasswordShow ? (
//                                       <Visibility
//                                         style={{ color: "black" }}
//                                         onClick={() =>
//                                           setNewPasswordShow(!newPasswordShow)
//                                         }
//                                       />
//                                     ) : (
//                                       <VisibilityOff
//                                         style={{ color: "black" }}
//                                         onClick={() =>
//                                           setNewPasswordShow(!newPasswordShow)
//                                         }
//                                       />
//                                     )}
//                                   </InputAdornment>
//                                 ),
//                               }}
//                             />
//                           </div>
//                           <div
//                             style={{ flexDirection: "row", display: "flex" }}
//                           >
//                             <TextField
//                               fullWidth
//                               placeholder={"Confirm Password "}
//                               type={confirmPasswordShow ? "text" : "password"}
//                               autoComplete={false}
//                               required
//                               value={confirmPassword}
//                               onChange={(e) => handleChange(e, "confirm")}
//                               InputProps={{
//                                 endAdornment: (
//                                   <InputAdornment position="end">
//                                     {confirmPasswordShow ? (
//                                       <Visibility
//                                         style={{ color: "black" }}
//                                         onClick={() =>
//                                           setConfirmPasswordShow(
//                                             !confirmPasswordShow
//                                           )
//                                         }
//                                       />
//                                     ) : (
//                                       <VisibilityOff
//                                         style={{ color: "black" }}
//                                         onClick={() =>
//                                           setConfirmPasswordShow(
//                                             !confirmPasswordShow
//                                           )
//                                         }
//                                       />
//                                     )}
//                                   </InputAdornment>
//                                 ),
//                               }}
//                             />
//                           </div>
// {showError && (
//   <span style={{ color: "red" }}>{showError}</span>
// )}
// <div className={classes.modalFooter1}>
//   <Button
//     variant="text"
//     size="medium"
//     onClick={handleCloseChangePassword}
//     style={{ backgroundColor: "#FFF", margin: "13px", color: "#000", border: '1px solid gray' }} >
//     Cancel
//   </Button>
//   <Button variant="text" size="medium" onClick={handleChangePassword} style={{ backgroundColor: "#060543", margin: "13px", color: "white" }}>
//     Save
//   </Button>
// </div>
//                         </Box>
//                       </Box>
//                     </Modal>
//                   </Box>
// {
//   props.isOpen && (
//     <Box style={{ position: 'absolute', bottom: '0px', justifyContent: 'center', right: '0px', left: '0px', display: 'flex', alignItems: 'center' }}>
//       &copy; Powered by {" "}
//       <a style={{ fontWeight: '500', fontSize: '18px', margin: '0px 5px', cursor: 'pointer', textDecoration: 'none', color: '#000' }} href={navigate} target="_blank"> HabileLabs </a>
//     </Box>
//   )
// }

//                 </Grid>
//               )}
//             </Grid>
//             {/* </SwipeableDrawer> */}
//           </div>
//         </React.Fragment>
//       ))}
//     </div>
//   );
// }

import React, { useState } from "react";
import { Box, Button, Modal, Typography, TextField, InputAdornment, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ReactSVG } from "react-svg";
import { useHistory } from "react-router-dom";
import HomePage from "../../assests/images/home-page.svg";
import LogOut1 from "../../assests/images/logout1.svg";
import UpdateProfile from "../../assests/images/update-profile.svg";
import changePassword1 from "../../assests/images/change-password.svg"
import useStyles from "../../assests/css/profileStyle";
import { Auth } from "aws-amplify";
import { useLocation } from 'react-router-dom';


function SideNavigation({ handlelogoutClose, logoutOpen, isOpen, anchor, toggleDrawer, handleDeviceAdded, isMobile, setSideDrawerOpen }) {
  const [open, setOpen] = useState(false);
  const [openName, setOpenName] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [userNameNew, setUserNameNew] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPasswordShow, setOldPasswordShow] = useState(false);
  const [newPasswordShow, setNewPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [showError, setShowError] = useState("");
  const location = useLocation();

  const isActive = (path) => location.pathname === path;


  const history = useHistory();
  const classes = useStyles();
  const navigate = "https://www.habilelabs.io/contact-us";

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenName = () => setOpenName(true);
  const handleCloseName = () => setOpenName(false);
  const handleOpenChangePassword = () => { console.log("afhkjsdlf"); setOpenChangePassword(true) };
  const handleCloseChangePassword = () => setOpenChangePassword(false);

  const changeUserName = () => {
    // Add username change logic
    handleCloseName();
  };
  const homePageNavigator = () => {
    handleDeviceAdded && handleDeviceAdded();
  };
  const handleChangePassword = () => {
    if (newPassword !== confirmPassword) {
      setShowError("Passwords do not match.");
      return;
    }
    // Add password change logic
    handleCloseChangePassword();
  };

  const signOutBtn = () => {
    Auth.signOut()
    handlelogoutClose()
  };
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {/* <div style={{ display: 'flex' }}>
        <img
          src={Habilelabs}
          name="file"
          className="img-thumbnail"
          height={isOpen ? 80 : 65}
          width={isOpen ? 80 : 40}
          alt="profile"
        />
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer}
        >
          <MenuIcon />
        </IconButton>
      </div> */}
      <Button
        onClick={() =>
          new URL(window.location.href).pathname === "/"
            ? homePageNavigator(anchor) && toggleDrawer(anchor, false)()
            : history.push(`/`)
        }
        style={{ justifyContent: isOpen ? 'flex-start' : 'center', textTransform: 'none', backgroundColor: isActive("/") ? "#f0f0f0" : "transparent",
        }}
      >
        <div style={{ display: 'flex', fontSize: '16px', color: '#000' }}>
          <ReactSVG src={HomePage} />
          {isOpen && <p style={{ paddingLeft: '20px', margin: '0px' }}>Home Page</p>}
        </div>
      </Button>

      <Button
        onClick={() => {
          history.push(`/edit-profile`);
          if (isMobile) {
            setSideDrawerOpen(false)
          }
        }}
        style={{ justifyContent: isOpen ? 'flex-start' : 'center', textTransform: 'none', backgroundColor: isActive("/edit-profile") ? "#f0f0f0" : "transparent" }}
      >
        <div style={{ display: 'flex', fontSize: '16px', color: '#000' }}>
          <ReactSVG src={UpdateProfile} />
          {isOpen && <p style={{ paddingLeft: '20px', margin: '0px' }}>Update Profile</p>}
        </div>
      </Button>

      <Button
        // onClick={handleOpenChangePassword}
        onClick={() => {
          history.push(`/change-password`);
          if (isMobile) {
            setSideDrawerOpen(false)
          }
        }}
        style={{ justifyContent: isOpen ? 'flex-start' : 'center', textTransform: 'none', backgroundColor: isActive("/change-password") ? "#f0f0f0" : "transparent" }}
      >
        <div style={{ display: 'flex', fontSize: '16px', color: '#000' }}>
          <ReactSVG src={changePassword1} />
          {isOpen && <p style={{ paddingLeft: '20px', margin: '0px' }}>Change Password</p>}
        </div>
      </Button>

      {/* <Button
        onClick={handleOpen}
        style={{ justifyContent: 'flex-start', textTransform: 'none' }}
      >
        <div style={{ display: 'flex', alignItems: 'center', fontSize: '16px', color: '#000' }}>
          <ReactSVG src={LogOut1} />
          {isOpen && <p style={{ paddingLeft: '20px', margin: '0px' }}>Log Out</p>}
        </div>
      </Button> */}

      {/* Logout Modal */}
      {/* <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box className={classes.modalBox} sx={{ padding: 4, backgroundColor: 'white' }}>
          <Typography
            id="modal-modal-description"
            className={classes.modalBod}
          >
            Are you sure you want to logout ?
          </Typography>
          <Box className={classes.modalFooter}>
            <Button
              variant="text"
              size="medium"
              onClick={handleClose}
              style={{
                backgroundColor: "#FFF",
                margin: "13px",
                color: "#000",
                border: '1px solid gray'
              }}
            >
              No
            </Button>
            <Button
              variant="text"
              size="medium"
              onClick={signOutBtn}
              style={{
                backgroundColor: "#060543",

                margin: "13px",
                color: "white",
              }}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal> */}
      {
        isOpen && <Box style={{ position: 'absolute', bottom: '0px', justifyContent: 'center', right: '0px', left: '0px', display: 'flex', alignItems: 'center' }}>
          &copy; Powered by {" "}
          <a style={{ fontWeight: '500', fontSize: '18px', margin: '0px 5px', cursor: 'pointer', textDecoration: 'none', color: '#000' }} href={navigate} target="_blank"> HabileLabs </a>
        </Box>
      }


      {
        logoutOpen && (
          <div className={classes.modalBackdrop} onClick={handlelogoutClose}
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1000
            }}
          >
            <Box className={classes.modalBox}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 4,
                backgroundColor: 'white',
                borderRadius: '8px',
                outline: 'none',
                width: '400px',
                height: '200px',
                position: 'relative'
              }}
              onClick={(e) => e.stopPropagation()}>
              <Typography
                id="modal-modal-description"
                // className={classes.modalBody}
                style={{
                  marginTop: '2px',
                  color: '#FFF',
                  backgroundColor: 'rgb(6, 5, 67)',
                  width: '100%',
                  position: 'absolute',
                  top: '0px',
                  padding: '15px'
                }}
              >
                Are you sure you want to logout?
              </Typography>
              <Box className={classes.modalFooter} style={{ marginTop: '80px' }}>
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={handlelogoutClose}
                  style={{
                    backgroundColor: "#FFF",
                    margin: "13px",
                    color: "#000",
                    border: '1px solid gray',
                  }}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  onClick={signOutBtn}
                  style={{
                    backgroundColor: "#060543",
                    margin: "13px",
                    color: "white",
                  }}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </div>
        )
      }

      {/* Change Name Modal */}
      <Modal open={openName} onClose={handleCloseName}>
        <Box sx={{ padding: 4, backgroundColor: 'white' }}>
          <Typography>Enter new name</Typography>
          <TextField
            fullWidth
            value={userNameNew}
            onChange={(e) => setUserNameNew(e.target.value)}
          />
          <Button onClick={handleCloseName}>Cancel</Button>
          <Button onClick={changeUserName} disabled={!userNameNew}>Confirm</Button>
        </Box>
      </Modal>

      {/* Change Password Modal */}
      <Modal open={openChangePassword} onClose={handleCloseChangePassword}>
        <Box sx={{ padding: 4, backgroundColor: 'white' }}>
          <Typography>Change Login Password</Typography>
          <TextField
            fullWidth
            placeholder="Old Password"
            type={oldPasswordShow ? "text" : "password"}
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {oldPasswordShow ? (
                    <Visibility onClick={() => setOldPasswordShow(!oldPasswordShow)} />
                  ) : (
                    <VisibilityOff onClick={() => setOldPasswordShow(!oldPasswordShow)} />
                  )}
                </InputAdornment>
              ),
            }}
          />
          {/* Repeat fields for new and confirm passwords */}
          <Button onClick={handleCloseChangePassword}>Cancel</Button>
          <Button onClick={handleChangePassword}>Save</Button>
        </Box>
      </Modal>
    </Box >
  );
}

export default SideNavigation;

